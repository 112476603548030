import axios from "axios";
import { PrefData, Prefs } from "../../components/types/population";
import { useState } from "react";

export const useCreateCheckBox = () => {
    const [prefs, setPrefs] = useState<Array<Prefs>>([])
    const createCheckbox = (resasApiKey) => {
        const getPrefApi = 'https://opendata.resas-portal.go.jp/api/v1/prefectures'
        
        axios.get<PrefData>(getPrefApi ,{headers:{'X-API-KEY':resasApiKey}}).then((res) => {
            setPrefs(res.data.result);
        })
        .catch((err) => console.log(err))
    }
    return {prefs, createCheckbox}
}