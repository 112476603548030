import { Box, Button, Typography } from "@mui/material"
import { FC } from "react";

type NavItem = {
    nav: string;
    onClick: () => void;
    icon: React.ReactNode;
}

type Props = {
    navItems: Array<NavItem>
}

export const Nav:FC<Props> = ( {navItems}) => {
    return (
        <>
            <Typography variant="h6" color="inherit" component="div" onClick={navItems[0].onClick} sx={{ '&:hover': { cursor: 'pointer', opacity:0.8 } }} >
                Prefecture
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {navItems.slice(1).map((item) => (
                    <Button key={item.nav} sx={{ color:"#fff", '&:hover': { opacity:0.8 } }} onClick={item.onClick}>
                        {item.nav}
                    </Button>
                ))}
            </Box>
        </>
    )

}