import { TableCell, TableHead, TableRow } from "@mui/material";

export const TableHeaderRow = ({tableRow, unitRow}) => {
    return (
        <TableHead>  
            <TableRow >
                {tableRow.map((Label, index) => (
                    index === 0 ? 
                        <TableCell key={index} align="left" colSpan={1}>{Label}</TableCell> : 
                        <TableCell key={index} align="center" colSpan={2}>{Label}</TableCell>
                ))}
            </TableRow>
            <TableRow>
                {unitRow.map((unit, index) => (
                    index === 0 ? 
                        <TableCell key={index} align="left" colSpan={1}>{unit}</TableCell> : 
                        <TableCell key={index} align="center" colSpan={1}>{unit}</TableCell>

                ))}
            </TableRow>
        </TableHead>
    );

}