import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../atoms/PrimaryButton";

export const Login = () => {
    const navigate = useNavigate();
    const onClickHome = () => navigate("/home");
    return (
        <>
            <PrimaryButton onClick={onClickHome}>ログイン</PrimaryButton>
        </>
    );
}