import { Box, Button } from "@mui/material"
import { FC, ReactNode } from "react"

type Props = {
    children: ReactNode;
    onClick: () => void;
}

export const PrimaryButton:FC<Props> = ({ children, onClick }) => {
    return (
        <Box sx={{ width:"100%", height:"100vh", textAlign:"center",display:"flex", alignItems: "center" }}>
            <Button variant="contained" onClick={ onClick } sx={{ margin:"0 auto" }} >{ children }</Button>
        </Box>
    )
}