import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './router/Router'
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@emotion/react';
import theme from './theme/theme';

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
    );
}
