import { Routes, Route } from "react-router-dom"
import { Page404 } from "../components/pages/Page404"
import { Login } from "../components/pages/Login"
import { Chat } from "../components/pages/Chat"
import { Setting } from "../components/pages/Setting"
import { Home } from "../components/pages/Home"
import { HomeRoutes } from "./HomeRoutes"
import { FC, memo } from "react"
import { HeaderOnly } from "../components/templates/HeaderOnly"

export const Router: FC = memo(() => {
    return (
        <Routes>
            {
                HomeRoutes.map((route) => (
                    <Route index={route.index} path={route.path} element={<HeaderOnly>{route.element}</HeaderOnly>} />
                ))
            };
            <Route index={false} path={"/*"} element={ <Page404 /> } />
        </Routes>
    );
});