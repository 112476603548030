import { TableRow, TableCell, TableBody } from "@mui/material"
import { tabelDataState } from "../../store/tabelDataState";
import { useRecoilValue } from "recoil";

// export const TableBodyRow = ({ tableData }) => {
export const TableBodyRow = () => {
    const tableData =  useRecoilValue(tabelDataState);

    return (
        <TableBody >
            {[...tableData].reverse().map((data) => (
                <TableRow key={data.totalYear}>
                    <TableCell align="left">{data.totalYear}</TableCell>
                   {data.totalValue ? <TableCell align="center">{data.totalValue}</TableCell> : <TableCell align="center">-</TableCell>}
                   {data.totalValue ? <TableCell align="center">{data.totalRate}</TableCell>  : <TableCell align="center">-</TableCell>}
                   {data.totalValue ? <TableCell align="center">{data.youngValue}</TableCell>  : <TableCell align="center">-</TableCell>}
                   {data.totalValue ? <TableCell align="center">{data.youngRate}</TableCell> : <TableCell align="center">-</TableCell>}
                   {data.totalValue ? <TableCell align="center">{data.workerValue}</TableCell>  : <TableCell align="center">-</TableCell>}
                   {data.totalValue ? <TableCell align="center">{data.workerRate}</TableCell>  : <TableCell align="center">-</TableCell>}
                   {data.totalValue ? <TableCell align="center">{data.oldValue}</TableCell>  : <TableCell align="center">-</TableCell>}
                   {data.totalValue ? <TableCell align="center">{data.oldRate}</TableCell>  : <TableCell align="center">-</TableCell>}
                </TableRow>
            ))}
        </TableBody>
    )

}