import axios from "axios"
import { DataPoint } from "../../components/types/population"
import { useCreateTableBody} from "./TableModule/useCreateTableBody" 
import { useCreateTableUnit } from "./TableModule/useCreateTableUnit";
import { useCreateTableHead } from "./TableModule/useCreateTableHead";

export const useCreateTable = () => {
    const { tableBody } = useCreateTableBody()
    const { unitRow, tableUnit } = useCreateTableUnit()
    const { tableRow, tableHead } = useCreateTableHead()

    const createTable = (e, resasApiKey) => {
        const prefCode = e.target.value
        const isChecked = e.target.checked
        const getPrefApi = `https://opendata.resas-portal.go.jp/api/v1/population/composition/perYear?prefCode=${prefCode}`
        console.log(isChecked);
        
        axios.get<DataPoint>(getPrefApi ,{headers:{'X-API-KEY':resasApiKey}}).then((res) => {
            const popGroup = res.data.result.data;
            const totalPop = popGroup[0].data;
            const youngPop = popGroup[1].data;
            const wokerPop = popGroup[2].data;
            const oldPop = popGroup[3].data;

            tableHead(popGroup);
            tableUnit();
            tableBody(totalPop, youngPop, wokerPop, oldPop, isChecked);
        })
        .catch((err) => console.log(err))

    }
    return { unitRow, tableRow, createTable }
}