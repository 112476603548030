import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import { FC } from "react";

type NavItem = {
    nav: string;
    onClick: () => void;
    icon: React.ReactNode;
}

type Props = {
    navItems: Array<NavItem>
    isOpen: boolean;
    toggleDrawer: (open: boolean) => void;
}

export const MenuDrawer:FC<Props> = ({navItems, isOpen, toggleDrawer}) => {
    return (
        <Drawer anchor={"right"} open={isOpen} onClose={() => toggleDrawer(false)} >
            <Toolbar />
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.nav} disablePadding>
                        <Box sx={{ width: "250px", display:"flex" }} mb={2}>
                            <ListItemButton  sx={{ '&:hover': { opacity:0.8 }, width: '100%'}} onClick={item.onClick} >
                                {item.icon}
                                <ListItemText primary={<Typography variant="h5">{item.nav}</Typography>} sx={{ color:"#1976d2", marginLeft:"30px" }} />
                            </ListItemButton>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
}