import { Table, TableContainer, Paper, TableHead, TableRow, TableBody, TableCell } from "@mui/material";
import { ChangeEvent, FC, memo, useEffect } from "react";
import { useCreateCheckBox } from "../../hooks/CheckBox/useCreateCheckBox";
import { useCreateTable } from "../../hooks/Table/useCreateTable";
import { Checkboxs } from "../molecules/Checkboxs";
import { TableHeaderRow } from "../molecules/TableHeaderRow";
import { TableBodyRow } from "../molecules/TableBodyRow";


export const Home: FC = memo(() => {
    const { prefs, createCheckbox } = useCreateCheckBox()
    const { unitRow, tableRow, createTable } = useCreateTable()
    const resasApiKey = process.env.REACT_APP_RESAS_API_KEY

    useEffect(() => createCheckbox(resasApiKey),[])

    const onClickPref = (e: ChangeEvent<HTMLInputElement>) => {  
        createTable(e, resasApiKey);
    };

    return (
        <>
            <Checkboxs prefs={prefs} onClickPref={onClickPref} />
            <TableContainer sx={{ maxHeight: 400, maxWidth: 1000 ,margin: "0 auto"}} component={Paper}>
                <Table stickyHeader size="small">
                    <TableHeaderRow tableRow={tableRow} unitRow={unitRow} />
                    <TableBodyRow />
                </Table>
            </TableContainer>
            
        </>
    );
})