import { useState } from "react";

export const useCreateTableHead = () => {
    const [tableRow, setTableRow] = useState<Array<string>>([]);
    const labelRow = [];
    
    const tableHead = (popGroup) => {
        labelRow.push("西暦")
        popGroup.map((dataGroup) => (
            labelRow.push(dataGroup.label)
        ))
        setTableRow(labelRow);
    }

    return {tableRow, tableHead}
    
}