import { useRecoilState } from "recoil";
import { tabelDataState } from "../../../store/tabelDataState";

export const useCreateTableBody = () => {
    const [tableData, setTableData] = useRecoilState(tabelDataState);
    const rows = [];

    const tableBody = (totalPop, youngPop, wokerPop, oldPop, isChecked) => {
        for (let i = 0; i < totalPop.length; i++) {
            if (tableData.length === 0 || tableData[i].totalValue === 0){
                const increaseRate = () => {
                    return ((totalPop[i].value - totalPop[0].value)*100/totalPop[0].value).toFixed(2)
                }
                const row = {
                    totalYear: totalPop[i].year,
                    totalValue: totalPop[i].value,
                    totalRate: increaseRate(),
                    youngValue: youngPop[i].value,
                    youngRate: youngPop[i].rate,
                    workerValue: wokerPop[i].value,
                    workerRate: wokerPop[i].rate,
                    oldValue: oldPop[i].value,
                    oldRate: oldPop[i].rate
                }
                rows.push(row)
            } else if (tableData.length !== 0) {
                const PopData = (oldValue, newPop) => {
                    return isChecked === true ?
                        {
                            value: oldValue + newPop[i].value,
                            rate: ((oldValue + newPop[i].value)/(tableData[i].totalValue + totalPop[i].value)).toFixed(2),
                            increaseRate: (((tableData[i].totalValue + totalPop[i].value) - (tableData[0].totalValue + totalPop[0].value))*100/(tableData[0].totalValue + totalPop[0].value)).toFixed(2)
                        }
                    :
                        {
                            value: oldValue - newPop[i].value,
                            rate: ((oldValue - newPop[i].value)/(tableData[i].totalValue - totalPop[i].value)).toFixed(2),
                            increaseRate: (((tableData[i].totalValue - totalPop[i].value) - (tableData[0].totalValue - totalPop[0].value))*100/(tableData[0].totalValue - totalPop[0].value)).toFixed(2)
                        }
                }
                const row = {
                    totalYear: totalPop[i].year,
                    totalValue: PopData(tableData[i].totalValue, totalPop).value,
                    totalRate: PopData(tableData[i].totalValue, totalPop).increaseRate,
                    youngValue: PopData(tableData[i].youngValue, youngPop).value,
                    youngRate: PopData(tableData[i].youngValue, youngPop).rate,
                    workerValue: PopData(tableData[i].workerValue, wokerPop).value,
                    workerRate:PopData(tableData[i].workerValue, wokerPop).rate,
                    oldValue: PopData(tableData[i].oldValue, oldPop).value,
                    oldRate: PopData(tableData[i].oldValue, oldPop).rate,
                }
                rows.push(row)
            } 
        }
        setTableData(rows);
    }
    console.log(tableData);
    return { tableBody }; 
}