import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        background: {
          default: '#f5f5f5', 
        },
        text: {
          primary: '#333333',
        },
    },
 });

export default theme;
