import { FC, ReactNode } from "react";
import { Header } from "../organisms/layout/Header";

type Props = {
    children: ReactNode;
}

export const HeaderOnly: FC<Props> = ( { children } ) => {
    return (
        <>
            <Header />
            {children}
        </>
    );
}