import { AppBar, Box, CssBaseline, IconButton, Toolbar } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FC, useState } from "react";
import ChatIcon from '@mui/icons-material/Chat';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SettingsIcon from '@mui/icons-material/Settings';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import HomeIcon from '@mui/icons-material/Home';
import { Nav } from "../../molecules/Nav";
import { MenuDrawer } from "./MenuDrawer";
import { useSetRecoilState } from "recoil";
import { tabelDataState } from "../../../store/tabelDataState";


export const Header:FC = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const setTableData = useSetRecoilState(tabelDataState);

    const onClickHome = () => navigate("/home");
    const onClickChat = () => navigate("/home/chat");
    const onClickContact = () => navigate("/home/contact");
    const onClickSetting = () => navigate("/home/setting");
    const onClickLogout = () => {
        setTableData([])
        alert('ログアウトしました')
        return navigate("/")
    }
    

    const navItems = [
        {
            nav: "Home",
            onClick: onClickHome,
            icon: <HomeIcon sx={{marginLeft:"10px", color:"#1976d2"}}  />
        },
        {
            nav: "Chat",
            onClick: onClickChat,
            icon: <ChatIcon sx={{marginLeft:"10px", color:"#1976d2"}}  />
        },
        {
            nav: "Contact",
            onClick: onClickContact,
            icon: <ContactMailIcon sx={{marginLeft:"10px", color:"#1976d2"}} />
        },
        {
            nav: "Setting",
            onClick: onClickSetting,
            icon: <SettingsIcon sx={{marginLeft:"10px", color:"#1976d2"}}/>
        },
        {
            nav: "Logout",
            onClick: onClickLogout,
            icon: <MeetingRoomIcon sx={{marginLeft:"10px", color:"#1976d2"}}/>
        },
    ];

    const toggleDrawer = (open:boolean) => setIsOpen(open);
    

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Nav navItems={navItems} />
                    <IconButton 
                        color="inherit" 
                        edge="end" 
                        onClick={() => toggleDrawer(true)}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <Menu />
                    </IconButton>
                    <MenuDrawer navItems={navItems} isOpen={isOpen} toggleDrawer={toggleDrawer} />
                </Toolbar>
            </AppBar>
        </Box>
    );
}