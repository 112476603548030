import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material"

export const Checkboxs = ({ prefs, onClickPref }) => {
    return (
        <Box sx={{ maxHeight: 440, maxWidth: 1000, margin: "0 auto", padding:"10px"}}>
            <FormControl>
                <FormLabel>Prefecture</FormLabel>
                <FormGroup row>
                    {prefs.map((pref) => (
                        <FormControlLabel
                            key={pref.prefCode} 
                            control={ <Checkbox value={pref.prefCode} onClick={onClickPref}/> } 
                            label={`${pref.prefCode} ${pref.prefName}`} 
                        /> 
                    ))}
                </FormGroup>
            </FormControl>
        </Box>
    )
}
