import { Chat } from "../components/pages/Chat";
import { Contact } from "../components/pages/Contact";
import { Home } from "../components/pages/Home";
import { Login } from "../components/pages/Login";
import { Setting } from "../components/pages/Setting";

export const HomeRoutes =  [
    {
        index: true,
        path: "/",
        element: <Login/>,
    },
    {
        index: false,
        path: "/home",
        element: <Home />
    },
    {
        index: false,
        path: "/home/chat",
        element: <Chat />
    },
    {
        index: false,
        path: "/home/contact",
        element: <Contact />
    },
    {
        index: false,
        path: "/home/setting",
        element: <Setting />
    },
]