import { useState } from "react";


export const useCreateTableUnit = () => {
    const [unitRow, setUnitRow] = useState<Array<string>>([]);
    
    const tableUnit = () => {
        const units = [];
        units.push("年")
        for (let i = 0; i < 4; i++) {
            units.push("人数(人)");
            if(i === 0) {
                units.push("増加率(%)");
            } else {
                units.push("割合(%)");
            }
        }
        setUnitRow(units)
    }


    return {unitRow, tableUnit}

}